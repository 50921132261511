<template>
  <CommonTooltip :tooltip="$t('cars-list.edit-note')" :class="$attrs.class">
    <DynamicAuctionItemListCarIcon
      v-bind="$attrs"
      :variant="car.hasNote ? 'primary' : 'border'"
      @click.stop="handleOpen"
      @mouseover="car.hasNote ? prefetch() : null"
    >
      <i class="ab-icon ab-icon-note h-full"></i>
    </DynamicAuctionItemListCarIcon>
  </CommonTooltip>

  <CarNote
    :is-open="isNoteModalOpen"
    :car-info="car.name"
    :car-id="car.id"
    :has-note="car.hasNote"
    @close="isNoteModalOpen = false"
    @update="(hasNote) => (car.hasNote = hasNote)"
  />
</template>

<script setup lang="ts">
import type { AuctionCar } from '@autobid/ui/types/Car'
import CarNote from '@autobid/ui/components/common/dialog/CarNote.vue'
import { useNote } from '@autobid/ui/composables/car/useNote'
import { useGetCar } from '@autobid/ui/composables/useCar'

type Props = {
  carId: AuctionCar['id']
}

const props = defineProps<Props>()

const isNoteModalOpen = ref(false)

const { getCar } = useGetCar()
const car = getCar(props.carId)

const { prefetch } = useNote(car.id, { enabled: false })

const handleOpen = async () => {
  if (car.hasNote) {
    await prefetch()
  }
  isNoteModalOpen.value = true
}
</script>
